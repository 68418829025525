<template>
    <div>
        <label for="card_element">Payment</label>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-12">
                <div id="card-element"></div>
                <button class="btn btn-success" @click="processPayment()">Pay now</button>
            </div>
        </div>
        
    </div>
</template>
<script>
// let stripe = Stripe('pk_test_NHwX8ytjmkawJV49ihax07q1');
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
export default {
    data() {
        
        return{
            stripe:{},
            cardElement:{},
            customer:{
                first_name:'',
                last_name:'',
                email:'',
                address:'',
                city:'',
                state:'',
                zip_code:''
            }
        }
    },
    async mounted(){
        this.stripe = await loadStripe('pk_test_NHwX8ytjmkawJV49ihax07q1');

        const elements = this.stripe.elements();
        this.cardElement = elements.create('card', {
            style:{
                base: {
                    iconColor: '#c4f0ff',
                    color: '#fff',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#87BBFD',
                },
                },
                invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE',
                },
            }
        });

        this.cardElement.mount('#card-element');

        
    },
    methods:{
        async processPayment(){
            // send the payment information to Laravel + stripe

            const {paymentMethod, error} = await this.stripe.createPaymentMethod(
                'card', this.cardElement, {
                    billing_details:{
                        name:this.customer.first_name + ' '+ this.customer.last_name,
                        email:this.customer.email,
                        address:{
                            line1:this.customer.address,
                            city:this.customer.city,
                            state:this.customer.state,
                            postal_code:this.customer.zip_code
                        }

                    }
                }
            );

            if(error){
                alert(error);
            }else{
                this.customer.payment_method_id = paymentMethod.id;
                this.customer.amount =1000;
                // this.customer.cart = JS

                axios.post('/api/v1/purchase', this.customer)
                .then((response) => {

                })
                .catch((error) => {
                    alert(error);
                })
            }
        }
    }
}
</script>